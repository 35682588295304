import React from "react"
import  { Mapa }  from "./mapa"
 

export const Footer = () => {
    return (
        <>
        <div id="main-footer" style={{ paddingTop:"25px"}}>
            <div className="container">
              
                <div className="row col-md-12">
                <div className="float-left col-md-3">
                <h2>Adresa</h2>
                <ul className="list-unstyled">
                    <li>13-og Oktobra 60a</li>
                    <li>Umka</li>
                    <li>Beograd</li>
                </ul>
                </div>
                <div className="float-left col-md-3">
                <h2>Kontakt</h2>
                <ul className="list-unstyled">
                <li>Marko Mitrović</li>
                <li><i className="fa fa-phone"></i> +381 66 52 568 56</li>
                </ul>
            </div>
            <div className="float-left col-md-6" style={{textAlign:"center"}}>
            <h2>Kod Doktora na Savi</h2>

                <Mapa/>
            </div>
            </div>
        </div>
        {/* <hr className="hr"/> */}
        <div className="footer-copyright shadow-sm" style={{ backgroundColor:"0"}}>
        
            <p className="col-sm">
               Made {new Date().getFullYear()} by &copy; Moderna Masina
            </p>

        </div>
        </div>
        </>
        )
    }