import React from "react"
import Iframe from "react-iframe"

export const Mapa = () => {
  return (
    <div id="mapa">
      <div className="container">
        <div className="col-md-12" >
          <div className="gmap_canvas">
          <Iframe id="gmap_canvas" 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d880.1333269829512!2d20.29791382925661!3d44.67645195233793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDTCsDQwJzM1LjIiTiAyMMKwMTcnNTQuNSJF!5e1!3m2!1ssr!2srs!4v1595418972023!5m2!1ssr!2srs?zoom=0"                  
                  frameborder="0" 
                  zoomIn="-3" 
                  allowfullscreen="" 
                  aria-hidden="false" 
                  tabindex="-3"
                  width="100%" 
                  height="250px"/>
          </div>
          </div>
      </div>
    </div>  
  )
}
