import React from "react"
import ImageGallery from 'react-image-gallery'

import foto1 from "../foto/1.jpg";
import foto2 from "../foto/2.jpg";
import foto3 from "../foto/3.jpg";
import foto4 from "../foto/4.jpg";
import foto5 from "../foto/5.jpg";
import foto6 from "../foto/6.jpg";
import foto7 from "../foto/7.jpg";
import foto8 from "../foto/8.jpg";

const images = [
    {
      original: foto1,
      thumbnail: foto1,
    },
    {
      original: foto2,
      thumbnail: foto2,
    },
    {
      original: foto3,
      thumbnail: foto3,
    },
    {
      original: foto4,
      thumbnail: foto4,
    },
    {
      original: foto5,
      thumbnail: foto5,
    },
    {
      original: foto6,
      thumbnail: foto6,
    },
    {
      original: foto7,
      thumbnail: foto7,
    },
    {
      original: foto8,
      thumbnail: foto8,
    },
]

 export class MyGallery extends React.Component {
    render() {
      return (
      <div id="galerija2">
      <ImageGallery items={images} 
      />
      </div>
    )}
}