import React from "react"
import { Opis } from "./intro"

export const About = () => {
    return (
    <div id="">
   <div className="row shadow text-center" style={{padding:"10px", marginBottom: "10px"}}>
        <div className="section-header" style={{wdith: "100% !important", margin: "0 auto" }}>
    
            <h3 id="about" className="" style={{color:"#18d26e"}}>Splav Kod Doktora</h3>
            {
                Opis.map((opisi)=>(
                <h3 key ={opisi.id}style={{fontWeight: "400", fontSize:"16px"}}>{opisi.opis}</h3>
                ))
            }
          
      
        </div>
    </div> 
    </div>
)}