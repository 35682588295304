import React from "react"

export const Izdavanje =()=>{

    return(
       
      <section id="call-to-action">
      <div className="container text-center" data-aos="zoom-in">
      <div className="container" data-aos="fade-up">

        <div className="section-header wow fadeInUp">
          <h3>Iznajmljivanje splava</h3>
          <p>Za informacije o dostupnosti splava za iznajmljivanje splava na dan, više dana, jedno noćenje i sl. pozovite kontakt osobu na <a href="#mapa"><b>telefon</b></a>. </p>
 
        </div>

        <div className="row">

          <div className="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="100">
            <div className="icon"><i className="fa fa-cutlery"></i></div>
            <h4 className="title">Kuhinja</h4>
            <p className="description">Kuhinja ima: vodu za piće, ugradnu ploču sa ringlama, frižider. Sto za ručavanje za 10-15 ljudi. </p>
          </div>
          <div className="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="200">
            <div className="icon"><i className="fa fa-shower"></i></div>
            <h4 className="title">Kupatilo</h4>
            <p className="description">Kupatilo sa tuškabinom.</p>
          </div>
          <div className="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="300">
            <div className="icon"><i className="fa fa-bed"></i></div>
            <h4 className="title">Kreveti</h4>
            <p className="description">1 francuski ležaj <br /> 2 klik-klak ležaja<br />
            Posteljina i peškiri su obezbeđeni.
            </p>
          </div>
          <div className="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="200">
            <div className="icon"><i className="fa fa-snowflake-o"></i>
              {/* <img src="../img/klima.png" width="50px"/>< */}
                </div>
            <h4 className="title">Klima uređaj</h4>
            <p className="description">Midea inverter. Grejanje do -25C. Ima wifi kontrolu.</p>
          </div>
          {/* <div className="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="300">
          <div className="icon"><i className="fa fa-paw"></i></div>
            <h4 className="title">Ljubimci</h4>
            <p className="description">Ljubimci su dobrodošli.</p>
          </div> */}
          <div className="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="400">
            <div className="icon"><i className="fa fa-car"></i></div>
            <h4 className="title">Parking</h4>
            <p className="description">Privatna parking mesta: 4. <br/> Javna parking mesta: 10.</p>
            <p className="description"></p>
          </div>

        </div>
</div>
      </div>
      <p className="text-center text-uppercase" style={{fontWeight:"400", fontSize:"18px"}}><b>Depozit je obavezan.</b>
      </p>
      <div className="icon"><i className="fa fa-money-bill-wave"></i></div>     
    </section>
     )

}