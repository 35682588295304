import React from "react"


export const Intro = () => {
    return (<>
  <div id="intro">
    <div className="intro-container">
    </div>
  </div>
</>
)}

