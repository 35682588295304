import React from "react"
// import Scroll from "react-scroll"

// const ScrollLink = Scroll.ScrollLink

export const Header = () => {
 
    return (
  <div id="header" style={{ padding:"5px" }} className="fixed-top header-transparent shadow">
    <div className="container-fluid">
    
      <div className="row justify-content-center">
        <div className="col-xl-11 d-flex align-items-center">
          <h1 className="logo mr-auto"><a href="index.html">Kod Doktora na Savi</a></h1>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
               <li className="active"><a href="index.html">Početna</a></li>
              <li><a href="#about">O splavu</a></li>
              <li><a href="#call-to-action">Iznajmljivanje</a></li>
              <li><a href="#galerija2">Galerija</a></li>
              <li style={{textAlign:"center"}}><a href="#mapa">Kontakt<br/><i className="fa fa-phone" ></i> 066 52 568 56</a></li>
              <li style={{float:"right"}}><a href="https://www.instagram.com/splav_kod_doktora/" target="blank" className="instagram"><i className="fa fa-instagram" style = {{fontSize:"30px"}}></i></a></li>
            </ul>
          </nav>
        </div>
      </div>
     
    </div>
  </div>
  )
}


// class Header extends Component {
//   render(){
//     return (
//         <div id="header" className="fixed-top header-transparent shadow">
//           <div className="container-fluid">
//             <div className="row justify-content-center">
//               <div className="col-xl-11 d-flex align-items-center">
//                 <h1 className="logo mr-auto"><a href="index.html">Kod Doktora na Savi</a></h1>
//                 <nav className="nav-menu d-none d-lg-block">
//                 <ScrollLink
//                 to="#about"
//                 spy={true} 
//                 smooth={true} 
//                 duration={500} 
//                 className="about"
//                 >
//                  O splavu    
//                  </ScrollLink> 
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </div>
//     )
//   }
// }
// export default Header