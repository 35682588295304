import foto1 from "../foto/1.jpg";
import foto2 from "../foto/2.jpg";
import foto3 from "../foto/3.jpg";
import foto4 from "../foto/4.jpg";
import foto5 from "../foto/5.jpg";
import foto6 from "../foto/6.jpg";
import foto7 from "../foto/7.jpg";
import foto8 from "../foto/8.jpg";


export const Fotografije = [
    {
        id: 0,
        naziv: "Prilaz",
        foto: foto1,
    },
    {
        id: 1,
        naziv: "Parking",
        foto: foto2,
    },
    {
        id: 2,
        naziv: "Sa reke",
        foto: foto3,
    },
    {
        id: 3,
        naziv: "Ulaz",
        foto: foto4,
    },
    {
        id: 4,
        naziv: "Pogled",
        foto: foto5,
    },
]
export const Opis = [
    {
        id: 0,
        opis: "Nalazi se na desnoj obali reke Save."
    },
    {
        id: 1,
        opis: "Namenjen je za sport i razonodu."
    },
    {
        id: 2,
        opis: "Dostupan za iznajmljivanje."
    }
]
export const Cards = [
    {
        id: 0,
        foto: foto6,
        naslov: "Mesto Umka",
        opis:"Nalazi se 15km južno od Ade Ciganlije, a 9km od Obrenovca ka Beogradu.",
        clasa: "ion-ios-location-outline",
    },
    {
        id: 1,
        foto: foto7,
        naslov: "Površina splava",
        opis:"Unutrašnja površina prizemlja: 4m x 3.3m Unutrašnja površina sprata: 6.5m x 4m Sunčana terasa: 7m x 3.3m",
        clasa: "ion-ios-paper-outline",
    },
    {
        id: 2,
        foto: foto8,
        naslov: "Parking mesta",
        opis: "Privatna parking mesta: ~ 4",
        opis2: "Javna parking mesta: 5-10",
        clasa: "ion-ios-outline",

    }
]
export const SplavUsluge = [
{
    id: 0,
    slika: "",
    naslov: "Kuhinja",
    opis: "Kuhinja ima: vodu za piće, ugradnu ploču sa ringlama, frižider. Sto za ručavanje za 10-15 ljudi. ", 
},
{
    id: 1,
    slika: "",
    naslov: "Kupatilo",
    opis: "Kupatilo je sa tuš kabinom.", 
},
{
    id: 2,
    slika: "",
    naslov: "Krevet",
    opis: "2 klik-klak i jedan francuski ležaj", 
},
{
    id: 3,
    slika: "",
    naslov: "Klima",
    opis: "Midea inverter. Grejanje do -25C. Ima wifi kontrolu.", 
},
{
    id: 4,
    slika: "",
    naslov: "Peškiri",
    opis: "Obezbeđeni peškiri i posteljina.", 
},
{
    id: 5,
    slika: "",
    naslov: "Parking",
    opis: "Privatna parking mesta: 4", 
},
]