import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/image-gallery.css';

import { Header } from './components/header';
import { Intro } from './components/intro.jsx';
import { About } from "./components/about";
import { Opis } from './components/opis.jsx';
import SimpleReactLightbox from 'simple-react-lightbox';
import { Izdavanje } from './components/izdavanje';
import { MyGallery } from './components/galerija';
import { Footer } from './components/footer';
import  InstaGrid  from './instagram.jsx';


 
const App =()=>{
  return (
  <>
  <Header/>
  <Intro/>
  <About/>
  <Opis/>
  <Izdavanje/>
  <SimpleReactLightbox>
  <MyGallery/>
  </SimpleReactLightbox>
  <InstaGrid account="splav_kod_doktora" numberOfMediaElements={12} discardVideos={false}/>
  <Footer/>
  </>
  )}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
