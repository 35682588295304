import React from "react"
import { Cards } from "./intro.js"

export const Opis = ()=>{
    return (
        <div className="container">
        <div className="row about-cols">
            {
              Cards.map((card)=>(
                <div className="col-md-4"  key={card.opis} data-aos="fade-up" data-aos-delay="100">
                
            <div className="about-col">
                  <div className="img">
                  <img src={card.foto} alt={card.opis} className="img-fluid"/>
                  <div className="icon">
                  <i className="ion-ios-location-outline"></i>
                  </div>
                  </div>
              <h2 className="title text-center">{card.naslov}</h2>
              <p className="text-center">{card.opis}<br/>{card.opis2}</p>
                  </div> 
                </div>
              ))
            }
        </div>
        </div>
    )
}

